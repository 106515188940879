<template>
  <div class="mymapM">
    <van-popup
      v-model="popupShow"
      round
      position="right"
      style="height: 100%; width: 80%"
      :get-container="getContainer"
    >
      <!-- get-container="#app" -->
      <div class="side">
        <p>价格筛选</p>
        <div class="range">
          <div class="range-div">
            <input type="text" placeholder="最低价" v-model="min" />
          </div>
          <div class="range-div">
            <input type="text" placeholder="最高价" v-model="max" />
          </div>
        </div>
        <p>类别</p>
        <div class="category">
          <div
            v-for="(item, index) in category"
            :key="index"
            :class="
              item.name == form.category
                ? 'category-div-active'
                : 'category-div'
            "
            :style="{ width: dynamicwidth(item.name) }"
            @click="choiceCategory(item)"
          >{{ item.name }}</div>
        </div>
        <div class="btn">
          <van-button
            round
            block
            type="default"
            color="#e7e7e7"
            size="small"
            @click="captchaLogin"
            class="chongzhi"
          >重置</van-button>
          <van-button
            round
            block
            type="info"
            color="linear-gradient(to right, #01B15B, #49D0A2)"
            size="small"
            @click="wancheng"
            class="wancheng"
          >完成</van-button>
        </div>
      </div>
    </van-popup>
    <div class="van_nav_bar_view" style="width: 100%">
      <!-- height: 320px; position: fixed; top: 0; z-index: 20 -->
      <div class="home_map_icon_view">
        <img
          style="width: 18px; height: 18px"
          src="../../../assets/black_return_view_bg.png"
          @click="gotlink"
          alt
        />
        <div class="search_view">
          <!-- <img src="../../../assets/gray_address_bg.png" alt="" /> -->
          <van-icon name="search" style="margin-left: 5px" size="15px" color="#B5B5B5" />
          <input v-model="query" type="text" placeholder="请输入" />
        </div>
        <div class="search_text" @click="toSearch">搜索</div>
        <!-- <div class="search_all_view">
        </div>-->
      </div>
    </div>
    <!-- offset-top="50" -->
    <!-- sticky -->
    <!-- swipeable -->
    <van-tabs
      class="van_tabs_view"
      line-width="20px"
      color="#00B05A"
      title-active-color="#00B05A"
      v-model="tabIndex"
      title-inactive-color="#333333"
      @click="tabclick"
    >
      <van-tab style="margin-bottom: 70px" v-for="(tagItem, tagIndex) in tabList" :key="tagIndex">
        <!-- :title="tagItem.tagName" -->
        <template #title>
          {{ tagItem.tagName }}
          <div v-if="tagItem.tagName == '价格'" class="up_down">
            <van-icon name="arrow-up" />
            <van-icon name="arrow-down" />
          </div>
          <div v-if="tagItem.tagName == '筛选'" class="screen">
            <!-- <img :src="tabIndex == 3 ? '../../../assets/shaixuan2.png' : '../../../assets/shaixuan.png'" alt="" :style="{}"> -->
            <img v-show="tabIndex == 3" src="../../../assets/shaixuan2.png" alt />
            <img v-show="tabIndex !== 3" src="../../../assets/shaixuan.png" alt />
          </div>
        </template>
        <div class="list_div">
          <van-list class="list_view" v-model="loading" :finished="finished">
            <van-cell
              v-for="(item, index) in commoditydata"
              class="list_item_view"
              :key="index"
              @click="toGoodsDetail(item)"
            >
              <!-- 售罄图标 -->
              <div v-show="item.stockingTime == 30 && item.totalStock == 0" class="Mask">
                <img src="@/assets/shoujin_bg.png" alt />
              </div>
              <!-- 次日达图标 -->
              <img v-show="(item.stockingTime == 1 || item.stockingTime == 2) && item.totalStock <= 0" class="torrmow_img" src="@/assets/ic_torrmow.png" alt />
              <van-image class="img_view" :src="imgApp + item.image"></van-image>
              <span
                style="
                  font-size: 13px;
                  color: #3f3f3f;
                  margin-left: 10px;
                  display: block;
                "
              >{{ item.name }}</span>
              <span
                style="
                  font-size: 12px;
                  color: #999999;
                  margin-left: 11px;
                  display: block;
                "
              >{{ item.brandName }}</span>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  margin-left: 10px;
                  margin-top: 10px;
                  justify-content: space-between;
                  width: 100%;
                "
              >
                <div class="price_view">
                  <span style="color: #df3436; font-size: 12px">₱</span>
                  <span style="color: #df3436; font-size: 18px">
                    {{
                    item.price
                    }}
                  </span>
                </div>
                <van-badge style="margin-right: 20px; display: flex; align-items: center" max="100">
                  <img style="width: 20px; height: 20px" src="../../../assets/add_view_bg.png" alt />
                </van-badge>
              </div>
            </van-cell>
          </van-list>
        </div>

        <!-- <div v-for="(item, index) in commoditydata" :key="index" class="commodity">
          <img :src="item.img" alt="" />
          <p>{{ item.text }}</p>
          <div class="price">
            <span><span>₱</span>{{ item.num }}</span>
            <van-icon class="add-icon" name="add" />
          </div>
        </div>-->
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  imgApp,
  getListOne,
  getListByWrapper,
  searchHistory
} from "@/service/api/index.js";
export default {
  data() {
    return {
      lists: [], //地点列表
      search_list: [], //搜索结果列表
      marker: "",
      noSearchShow: false, //无搜索结果提示，无搜索结果时会显示暂无搜索结果
      tabList: [
        {
          tagName: "综合"
        },
        {
          tagName: "销量"
        },
        {
          tagName: "价格"
        },
        {
          tagName: "筛选"
        }
      ],
      commoditydata: [],
      tabIndex: "",
      popupShow: false,
      category: [],
      form: {
        category: ""
      },
      finished: false,
      imgApp,
      loading: false,
      price: false,
      min: "",
      max: "",
      categoryid: "",
      query: ""
    };
  },
  created() {
    this.query = this.keyWord;
    getListOne().then(res => {
      this.category = res.data;
    });
    searchHistory({ search: this.query }).then(res => {
      if (res.status == 200) {
        getListByWrapper({ keyWord: this.query }).then(res => {
          this.commoditydata = res.data;
          this.finished = true;
        });
      }
    });
  },
  mounted() {},
  computed: {
    keyWord: {
      get() {
        return this.$route.query.keyWord;
      }
    }
  },
  methods: {
    toPath(item) {
      this.$router.push({
        path: "newAddress",
        query: {
          address: item.address
        }
      });
    },
    inputListener() {
      var input = document.querySelector("input").value;
      if (input.length === 0) {
      } else {
        console.log("aaaa");
      }
    },
    toSearch() {
      if (this.query == "") {
        Toast("请输入搜索商品名称");
      } else {
        if (this.keyWord == this.query) {
          return;
        } else {
          this.$router.replace({
            path: "/detailsAddress",
            query: {
              keyWord: this.query
            }
          });
          this.ListByWrapper({ keyWord: this.query });
        }
      }
      // console.log("执行搜索");
    },
    clear() {
      // 清楚最近搜索
    },
    tabclick(name, title) {
      if (this.tabIndex == 0) {
        this.ListByWrapper({ keyWord: this.query });
      } else if (this.tabIndex == 1) {
        this.ListByWrapper({ keyWord: this.query, sale: 1 });
      } else if (this.tabIndex == 2) {
        this.price = !this.price;
        this.ListByWrapper({
          keyWord: this.query,
          price: this.price ? 0 : 1
        });
      } else if (this.tabIndex == 3) {
        this.popupShow = true;
      }
    },
    dynamicwidth(data) {
      return String(data).length * 20 + 12 + "px";
    },
    choiceCategory(data) {
      // console.log(data)
      this.form.category = data.name;
      this.categoryid = data.id;
    },
    captchaLogin() {
      this.min = "";
      this.max = "";
      this.categoryid = "";
      this.form.category = "";
    },
    wancheng() {
      this.ListByWrapper({
        keyWord: this.query,
        min: this.min,
        max: this.max,
        id: this.categoryid
      });
      this.popupShow = false;
    },
    ListByWrapper(data) {
      searchHistory({ search: this.query }).then(res => {
        if (res.status == 200) {
          getListByWrapper(data).then(res => {
            // getListByWrapper({ keyWord: this.query }).then((res) => {
            this.commoditydata = res.data;
            this.finished = true;
          });
        }
      });
    },
    toGoodsDetail(item) {
      this.$router.push({
        path: "/goodsDetail",
        query: {
          id: item.id
        }
      });
    },
    gotlink() {
      this.$router.replace({
        path: "/searchAddress"
      });
    },
    getContainer() {
      return document.querySelector(".mymapM");
    }
  }
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .van_nav_bar_view {
    max-width: 540px;
    // height: 50px;
  }
  .search_input_view {
    max-width: 540px;
    width: 100%;
    background: turquoise;
  }
}
.van_nav_bar_view {
  // height: 50px;
}
.van-cell__value {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mymapM {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.search_list_item {
  // position: absolute;
  // top: 330px;
  max-width: 540px;
  width: 100%;
  background: white;
  width: 100%;
}
.search_input_view {
  width: 80%;
  height: 30px;
  line-height: 30px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  margin: 0 auto;
  padding-left: 15px;
  border: 1px solid #00b05a;

  input {
    flex: 1;
    height: 30px;
    margin-right: 10px;
    margin-left: 5px;
    border: none;
    background: none;
  }
}

.con-box {
  .mapbox {
    width: 100%;
    height: 236px;
    padding: 10px 0;
    background: #eceeee;
    .map {
      width: 100%;
      height: 236px;
    }
  }
}

// .list_view {
//   width: 100%;
//   height: 64px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   border-bottom: 1px solid #e5e5e5;
//   background: white;
//   div {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     margin-left: 15px;
//     justify-content: space-between;
//     span:frist-of-type {
//       font-size: 15px;
//       font-weight: 400;
//       color: black;
//     }
//     span:last-of-type {
//       font-size: 12px;
//       color: #787d84;
//       font-weight: 400;
//       margin-right: 15px;
//     }
//   }
//   span {
//   }
// }
.search_all_view {
  width: 100%;
  background: white;
  height: 40px;
}
.search_view {
  // width: 250px;
  // height: 30px;
  // border-radius: 20px;
  // margin: 0 auto;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  width: 70%;
  height: 25px;
  background: white;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #00b05a;
}
.search_view img {
  width: 16px;
  height: 16px;
  margin-left: 7px;
}
.search_view input {
  flex: 1;
  margin-left: 4px;
  border: none;
  background: none;
  font-size: 14px;
}
.search_view input::-webkit-input-placeholder {
  font-size: 12px;
}

.home_map_icon_view {
  max-width: 540px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 44px;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  top: 0;
  background: white;
  box-sizing: border-box;
}
.search_text {
  font-size: 14px;
  color: #00b05a;
  cursor: pointer;
}
.bot {
  padding: 0 10px;
  margin: 20px 0;
  span {
    font-size: 14px;
    color: #818181;
  }
}
.lately {
  padding: 0 10px;
  margin: 10px 0 20px;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 14px;
    color: #818181;
  }
  img {
    width: 16px;
    height: 16px;
  }
}
.classification {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  div {
    height: 24px;
    background: #e4e4e4;
    border-radius: 12px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px 10px 0;
  }
}
/deep/ .van-tab__text {
  display: flex;
}
.up_down {
  display: flex;
  flex-direction: column;
  /deep/ .van-icon {
    font-size: 10px;
  }
}
.screen {
  img {
    width: 12px;
    // height: 5px;
  }
}
.van-tabs {
  // top: 50px;
  // position: absolute;
  // height: 100%;
  flex: 1;
  width: 100%;
  overflow: hidden;
}
/deep/ .van-tabs__content {
  height: 100%;
}
/deep/ .van-tab__pane {
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  // flex-direction: row;
  .commodity {
    width: 45%;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    margin: 10px 0 0 0;
    img {
      width: 100px;
      height: 100px;
    }
    p {
      height: 50px;
      padding: 0 10px;
      font-size: 10px;
    }
    .price {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      box-sizing: border-box;
      span {
        color: red;
        font-size: 18px;
        span {
          font-size: 12px;
        }
      }
      .add-icon {
        color: #00b05a;
        font-size: 0.64rem;
      }
    }
  }
}
.side {
  padding: 20px;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  p {
    font-size: 14px;
  }
  .range {
    display: flex;
    justify-content: space-between;
    .range-div {
      width: 45%;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      background: #e7e7e7;
      input {
        width: 40%;
        background: none;
        outline: none;
        border: none;
        font-size: 14px;
        padding: 0;
      }
      input::-webkit-input-placeholder {
        font-size: 12px;
      }
      input:focus {
        border: none;
      }
    }
  }
  .category {
    display: flex;
    flex-wrap: wrap;
    .category-div {
      height: 25px;
      background: #e7e7e7;
      border: 1px solid #e7e7e7;
      border-radius: 15px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 10px 10px 0;
    }
    .category-div-active {
      height: 25px;
      background: #e8f6ee;
      border: 1px solid #00b05a;
      border-radius: 15px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 10px 10px 0;
    }
  }
  .btn {
    position: absolute;
    bottom: 20px;
    width: 85%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .chongzhi {
      color: black !important;
      width: 30%;
    }
    .wancheng {
      width: 60%;
    }
  }
}
.list_div {
  width: 100%;
  height: 100%;
  .list_view {
    width: 100%;
    height: 100%;
    overflow: scroll;
    // position: absolute;
    // top: 108px;
    display: flex;
    justify-content: space-around;
    align-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    // margin-bottom: 100px;
    background: none;
    border: none;
    .list_item_view {
      height: 240px;
      width: 45%;
      margin: 3px auto;
      background: white;
      box-shadow: 0px 10px 18px 0px rgba(153, 153, 153, 0.18);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      .img_view {
        width: 118.5px;
        height: 118.5px;
        background: yellowgreen;
        margin-left: 5px;
      }
    }
  }
  .list_view:after {
    content: "";
    width: 50%;
    border: 1px solid transparent;
  }
  /deep/ .van-list__loading {
    width: 100%;
  }
}
/deep/ .sellOut {
  background-color: red !important;
}
.Mask {
  width: 100%;
  height: 100%;
  // background-color: #ccc;
  opacity: 0.5;
  position: absolute;
  z-index: 10;
}
.torrmow_img {
  width: 70px;
  position: absolute;
  z-index: 10;
  left: 0;
}
</style>
